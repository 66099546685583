export default [
  {
    header: 'Theo dõi nhanh',
    resource: 'dashboard',
    action: 'read',
  },
  // {
  //   title: 'Calendar',
  //   icon: 'LayersIcon',
  //   route: 'calendar',
  //   resource: 'dashboard',
  //   action: 'read',
  // },
  {
    title: 'Dashboard thống kê',
    icon: 'LayersIcon',
    route: 'dashboard',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Sơ đồ tòa nhà',
    icon: 'LayoutIcon',
    route: 'shortcuts',
    resource: 'shortcut',
    action: 'read',
  },
  // {
  //   title: 'Nhắn tin',
  //   icon: 'MessageSquareIcon',
  //   route: 'apps-chat',
  // },

];
