/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard';
import settings from './settings';
// import apartment from './apartment';
// import fee from './fee';
// import finance from './finance';
// import staff from './staff';
// import contract from './contract';
// import inventory from './inventory';
// import account from './account';
import report from './report';
import admin from './admin';
import category from './category';

// Array of sections
export default [
  ...admin,
  ...dashboard,
  ...category,
  // ...apartment,
  // ...fee,
  // ...contract,
  // ...finance,
  // ...inventory,
  ...report,
  // ...staff,
  ...settings,
  // ...account,

];
