import { isEnableBed, isEnableLocationModule } from "@/auth/utils";

const operationArray = [
  {
    header: 'Quản lý & Vận hành',
    resource: 'operation',
    action: 'read',
  },
];

const categoryArray = [
  {
    title: 'Tòa nhà',
    route: 'apartment-list',
    icon: 'HomeIcon',
    resource: 'apartment',
    action: 'read',
  },
  {
    title: 'Căn hộ/Phòng',
    route: 'room-list',
    icon: 'SidebarIcon',
    resource: 'room',
    action: 'read',
  },
];

if (isEnableLocationModule()) {
  categoryArray.splice(0, 0, {
    title: 'Khu vực',
    route: 'location-list',
    icon: 'MapPinIcon',
    resource: 'location',
    action: 'read',
  });
}

if (isEnableBed()) {
  categoryArray.push({
    title: 'Giường',
    route: 'bed-list',
    icon: 'ServerIcon',
    resource: 'bed',
    action: 'read',
    disabled: !isEnableBed(),
  });
}
categoryArray.push({
  title: 'Tài sản',
  route: 'asset-list',
  icon: 'UmbrellaIcon',
  resource: 'asset',
  action: 'read',
});
categoryArray.push({
  title: 'Phí dịch vụ',
  route: 'fee-list',
  icon: 'DropletIcon',
  resource: 'fee',
  action: 'read',
});

operationArray.push({
  title: 'Danh mục dữ liệu',
  route: 'apartment-list',
  icon: 'LayersIcon',
  resource: 'category',
  action: 'read',
  children: categoryArray,
  // tag: 'new',
});

// contract
const contractArray = [
  {
    title: 'Khách hẹn xem',
    route: 'lead-list',
    icon: 'StarIcon',
    resource: 'lead',
    action: 'read',
  },
  {
    title: 'Đặt cọc',
    route: 'reservation-list',
    icon: 'FeatherIcon',
    resource: 'reservation',
    action: 'read',
  },
  {
    title: 'Hợp đồng thuê',
    route: 'contract-list',
    icon: 'FileTextIcon',
    resource: 'contract',
    action: 'read',
  },
  {
    title: 'Khách hàng',
    route: 'tenant-list',
    icon: 'UsersIcon',
    resource: 'tenant',
    action: 'read',
  },
  {
    title: 'Phương tiện',
    route: 'vehicle-list',
    icon: 'TruckIcon',
    resource: 'vehicle',
    action: 'read',
  },
];
operationArray.push({
  title: 'Khách hàng',
  route: 'apartment-list',
  icon: 'BookOpenIcon',
  resource: 'contract-and-tenant',
  action: 'read',
  children: contractArray,
});

// finance
const financeArray = [
  {
    title: 'Ghi chỉ số',
    route: 'meter-log-list',
    icon: 'PenToolIcon',
    resource: 'meter-log',
    action: 'read',
  },
  {
    title: 'Hoá đơn',
    route: 'invoice-list',
    icon: 'FileTextIcon',
    resource: 'invoice',
    action: 'read',
  },
  {
    title: 'Thu chi',
    route: 'income-expense-list',
    icon: 'FolderPlusIcon',
    resource: 'income-expense',
    action: 'read',
  },
];

operationArray.push({
  title: 'Tài chính',
  icon: 'DollarSignIcon',
  resource: 'finance',
  action: 'read',
  children: financeArray,
});

operationArray.push({
  title: 'Gửi thông báo',
  icon: 'BellIcon',
  route: 'apps-notification',
  resource: 'notification',
  action: 'read',
});
operationArray.push({
  title: 'Sự cố/Công việc',
  icon: 'CheckSquareIcon',
  route: 'apps-todo',
  resource: 'task',
  action: 'read',
  tag: '',
  tagVariant: 'danger',
});

export default operationArray;
