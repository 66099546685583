export default [
  {
    header: 'Quản trị thông tin',
    resource: 'admin',
    action: 'read',
  },
  // {
  //   title: 'Danh sách tòa nhà',
  //   route: 'apartment-admin-list',
  //   icon: 'HomeIcon',
  //   resource: 'apartment-admin',
  //   action: 'manage',
  // },
  {
    title: 'Danh sách chủ nhà',
    route: 'host-admin-list',
    icon: 'UserIcon',
    resource: 'host-admin',
    action: 'read',
  },
  // {
  //   title: 'Danh sách khách hàng',
  //   route: 'tenant-admin-list',
  //   icon: 'UsersIcon',
  //   resource: 'tenant-admin',
  //   action: 'read',
  // },
  // {
  //   title: 'Danh sách giao dịch',
  //   route: 'order-admin-list',
  //   icon: 'CreditCardIcon',
  //   resource: 'order-admin',
  //   action: 'read',
  // },
  {
    header: 'Cấu hình hệ thống',
    resource: 'admin',
    action: 'read',
  },
  {
    title: 'Banner hiển thị',
    route: 'banners',
    icon: 'CreditCardIcon',
    resource: 'order-admin',
    action: 'read',
  },
];
